export const BREAKPOINT_MINS = {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xl2: 1536,
    xl3: 1921,
};

// @ts-ignore
export const mq: {xs: string, sm: string, md: string, lg: string, xl: string, xl2: string, xl3: string} = {}
Object.keys(BREAKPOINT_MINS).forEach((key) => {
    // @ts-ignore
    mq[key] = `@media (min-width: ${BREAKPOINT_MINS[key]}px)`;
});
