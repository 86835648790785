import useNavbarFontColor from "@/hooks/useNavbarFontColor";
import SmallArrow from "@/svg/SmallArrow";
import { css } from "@emotion/react";
import { FC } from "react";
import { H9 } from "../Typography";
import NavbarDesktopFolderTooltipFeatures from "./NavbarDesktopFolderTooltipFeatures";
import NavbarDesktopFolderTooltipSports from "./NavbarDesktopFolderTooltipSports";
import NavbarDesktopFolderTooltipResources from "./NavbarDesktopFolderTooltipResources";

interface Props {
  label: string;
}

const NavbarDesktopFolder: FC<Props> = ({ label }) => {
  const { color } = useNavbarFontColor();
  const className = `desktop-header-folder-${label.toLowerCase()}`;
  return (
    <>
      <div
        data-tooltip-id={`${label.toLowerCase()}-tooltip`}
        className={className}
        css={css`
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 8px;
          position: relative;
          &:hover {
            .line-under-header-folder {
              width: 14px;
              opacity: 1;
            }
          }
        `}
      >
        <H9
          css={css`
            color: ${color};
          `}
        >
          {label}
        </H9>
        <SmallArrow
          css={css`
            color: ${color};
            opacity: 0.5;
          `}
        />
        <div
          className="line-under-header-folder"
          css={css`
            position: absolute;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 24px;
            width: 0;
            opacity: 0;
            transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out;
          `}
        >
          <hr
            css={css`
              display: none;
              border-top: 2px solid ${color};
            `}
          />
        </div>
      </div>
      {label === "Features" && <NavbarDesktopFolderTooltipFeatures />}
      {label === "Sports" && <NavbarDesktopFolderTooltipSports />}
      {label === "Resources" && <NavbarDesktopFolderTooltipResources />}
    </>
  );
};

export default NavbarDesktopFolder;
