import { css } from "@emotion/react";
import JWHeaderLogo from "@/svg/JWHeaderLogo";
import NavbarDesktopFolder from "../NavbarDesktop/NavbarDesktopFolder";
import NavbarDesktopLink from "../NavbarDesktop/NavbarDesktopLink";
import WatchDemoNavbar from "@/svg/WatchDemoNavbar";
import useNavbarFontColor from "@/hooks/useNavbarFontColor";
import Link from "next/link";
import { signInUrl, signUpPath } from "@/constants/paths";
import { backgroundColor, dividerColor } from "@/constants/colors";
import Container from "../Container/Container";
import { useRouter } from "next/router";

const HeaderDesktop = () => {
  const { color } = useNavbarFontColor();
  const borderColor = color === "#fff" ? backgroundColor : color;
  const { pathname } = useRouter();
  const router = useRouter();

  return (
    <nav
      css={css`
        border-bottom: 1px solid
          ${pathname === "/sign-up" ? dividerColor : "transparent"};
      `}
    >
      <Container
        css={css`
          display: flex;
          justify-content: space-between;
          padding-top: 16px;
          padding-bottom: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 600px;
            gap: 2px;
          `}
        >
          <Link
            href="/"
            aria-label="Jersey Watch Logo"
            css={css`
              padding: 8px;
              height: 20px;
            `}
          >
            <JWHeaderLogo
              css={css`
                color: ${color};
                margin-right: 8px;
              `}
            />
          </Link>
          <NavbarDesktopFolder label="Features" />
          <NavbarDesktopFolder label="Sports" />
          <NavbarDesktopLink isPricing href="/pricing">
            Pricing
          </NavbarDesktopLink>
          <NavbarDesktopFolder label="Resources" />
        </div>
        <div
          css={css`
            display: flex;
            max-width: 330px;
            align-items: center;
            white-space: nowrap;
            justify-content: space-between;
            gap: 5px;
            max-width: 100%;
            @media (min-width: 1050px) {
              gap: 15px;
            }
          `}
        >
          <Link
            href="/demo"
            css={css`
              padding: 8px;
              border: 2px solid transparent;
              transition: border-color 0.2s ease-in-out;
              border-radius: 10px;
              &:hover {
                border-color: ${borderColor};
              }
            `}
          >
            <WatchDemoNavbar color={color} />
          </Link>
          <NavbarDesktopLink href={signInUrl}>Log In</NavbarDesktopLink>
          {router.pathname === "/" ? (
            <NavbarDesktopLink
              css={css`
                border: 2px solid #59ffa0;
                border-radius: 10px;
                * {
                  color: #59ffa0 !important;
                }
              `}
              isGetStarted
              href={signUpPath}
            >
              Get Started
            </NavbarDesktopLink>
          ) : (
            <NavbarDesktopLink isGetStarted href={signUpPath}>
              Get Started
            </NavbarDesktopLink>
          )}
        </div>
      </Container>
    </nav>
  );
};

export default HeaderDesktop;
