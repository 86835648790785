import { signUpPath } from "@/constants/paths";
import { css, SerializedStyles } from "@emotion/react";
import Link from "next/link";
import Button from "./Button";

interface GetStartedButtonProps {
  onClick?: () => void;
  text?: string;
  fullWidth?: boolean;
  isHeaderMobile?: boolean;
  css?: SerializedStyles;
}

const GetStartedButton = ({
  onClick,
  text,
  fullWidth,
  isHeaderMobile,
  ...props
}: GetStartedButtonProps) => {
  return (
    <Link
      href={signUpPath}
      onClick={onClick}
      css={
        fullWidth
          ? css`
              display: flex;
              width: 100%;
              justify-content: center;
            `
          : css`
              display: block;
              width: fit-content;
            `
      }
    >
      <Button
        jwType="dark"
        css={css`
          min-width: ${isHeaderMobile ? "124px" : "177px"};
          height: ${isHeaderMobile ? "40px" : "50px"};
        `}
        {...props}
      >
        <span
          css={css`
            margin-right: 12px;
            font-size: ${isHeaderMobile ? "14px" : "16px"};
            line-height: ${isHeaderMobile ? "16.8px" : "19.2px"};
            font-weight: 350px;
          `}
        >
          {text || "Get Started"}
        </span>
        {!text && (
          <svg
            width="6"
            height="12"
            viewBox="0 0 6 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L5 6L0.999999 11"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </Button>
    </Link>
  );
};

export default GetStartedButton;
