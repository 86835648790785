import { css } from "@emotion/react";
import FooterSectionParent from "./FooterSectionParent";
import FooterSectionChild from "./FooterSectionChild";
import {
  blogPostPath,
  cookiesPath,
  privacyPath,
  termsPath,
} from "@/constants/paths";
import navbarData from "@/constants/navbarData";
import FooterHR from "./FooterHR";
import { Body } from "../Typography";
import { iconColor } from "@/constants/colors";
import { mq } from "@/constants/breakpoints";
import Container from "../Container/Container";
import FooterLogoReviewsSocials from "./FooterLogoReviewsSocials";
import HelpScout from "./HelpScout";

interface FooterProps {}

const Footer = ({}: FooterProps) => {
  const blogPosts = process.env.footerBlogs! as any;

  return (
    <footer
      css={css`
        background-color: #1b2d39;
        padding-top: 60px;
        text-align: center;
      `}
    >
      <HelpScout />
      <Container>
        <FooterLogoReviewsSocials
          css={css`
            ${mq["lg"]} {
              display: none;
            }
          `}
        />
        <div
          css={css`
            text-align: left;
            margin: 60px 0;
            ${mq["md"]} {
              display: flex;
              justify-content: space-evenly;
              gap: 80px;
            }
          `}
        >
          <FooterLogoReviewsSocials
            css={css`
              display: none;
              ${mq["lg"]} {
                display: block;
              }
            `}
          />
          <FooterSectionParent label="Features">
            {navbarData.features.map(({ title, href }, index) => (
              <FooterSectionChild key={index} href={href}>
                {title}
              </FooterSectionChild>
            ))}
          </FooterSectionParent>
          <FooterHR />
          <FooterSectionParent label="Sports">
            {navbarData.sports.map(({ title, href }, index) => (
              <FooterSectionChild key={index} href={href}>
                {title}
              </FooterSectionChild>
            ))}
          </FooterSectionParent>
          <FooterHR />
          <FooterSectionParent label="Resources">
            {navbarData.resources.map(({ title, href }, index) => (
              <FooterSectionChild key={index} href={href}>
                {title}
              </FooterSectionChild>
            ))}
            <Body
              // @ts-ignore
              onClick={() => window.Beacon("open")}
              css={css`
                cursor: pointer;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                color: ${iconColor};
                &:hover {
                  color: #e8ebf9;
                }
              `}
            >
              Contact Us
            </Body>
          </FooterSectionParent>
          <FooterHR />
          <FooterSectionParent label="Learn">
            {blogPosts.map(({ slug, title }: any) => (
              <FooterSectionChild href={blogPostPath(slug)} key={slug}>
                {title}
              </FooterSectionChild>
            ))}
          </FooterSectionParent>
        </div>
        <FooterHR style={{ marginBottom: 0 }} show />
        <div
          css={css`
            padding: 24px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Body
            css={css`
              font-weight: 500;
              font-size: 14px;
              line-height: 140%;
              color: ${iconColor};
            `}
          >
            © 2016 — 2025
          </Body>
          <div
            css={css`
              display: flex;
              gap: 20px;
              ${mq["sm"]} {
                gap: 45px;
              }
            `}
          >
            <FooterSectionChild href={privacyPath}>Privacy</FooterSectionChild>
            <FooterSectionChild href={termsPath}>Terms</FooterSectionChild>
            <FooterSectionChild href={cookiesPath}>Cookies</FooterSectionChild>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
