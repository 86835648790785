import Head from "next/head";
import { useRouter } from "next/router";

const AppCanonical = () => {
  const router = useRouter();
  const plainPath = router.asPath.split("?")[0].split("#")[0];

  if (process.env.NEXT_PUBLIC_APP_ENV !== "production") return null;

  return (
    <Head>
      <link rel="canonical" href={`https://www.jerseywatch.com${plainPath}`} />
    </Head>
  );
};

export default AppCanonical;
