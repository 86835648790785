export const backgroundColor = "#F4F6FD";
export const darkGreenColor = "#10C3C3";
export const darkRedColor = "#D8204C";
export const blueColor = "#DAF2FF";
export const darkColor = "#243B4A";
export const dividerColor = "#EFF1F8";
export const iconColor = "#D3D6E9";
export const lightBlueColor = "#048CDD";
// export const lightBlueColor = "#047BC3";
export const lightColor = "#CAD4E8";
export const mutedBlueColor = "#F5FBFF";
export const mutedGreenColor = "#DBFCF8";
export const secondaryBlueColor = "#72CAFF";
export const secondaryRedColor = "#FF5A6E";
export const textColor = "#657197";
export const gray600Color = "#475467";
export const gray500Color = "#667085";
